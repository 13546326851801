<template>
    <div class="main-container" dir="ltr">
        <div class="row mb-4">
            <div  class="col-md-12 my-auto">
                <h5 style="text-align: right;margin-top: 15px;">الاساتذة </h5>
            </div>
        </div>
        <div class="row justify-content-center " dir="rtl"  data-slick='{"slidesToShow": , "slidesToScroll": }'>
            <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="publish in AllTeachers" :key="publish.id">
                <div class="video-item-card">
                    <a @click="GoToArtistsContent(publish)">
                        <div class="video-thumb">
                            <img v-lazy="publish.img" class="custom-logo" alt="Sayara">
                        </div>
                    </a>
                    <div class="video-content">
                        <div class="d-flex">
                            <div class="my-auto ">
                                <a @click="GoToArtistsContent(publish)">
                                    <h5>{{publish.cp_name}}</h5>
                                </a>
                                <ul class="list-inline">
                                    <li class="list-inline-item">
                                        <a class="author " @click="GoToArtistsContent(publish)">
                                            {{publish.cp_name}}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from '@vue/reactivity';
import { useRouter } from "vue-router"
import { useCookie } from 'vue-cookie-next'
export default {
name:'AppPhrases',
async setup() {
    const router = useRouter();
    const cookie = useCookie();
    const AllTeachers = ref([]);
    
    try {
      await HTTP.get(`GetAllTeachers.php`).then((res) => {
        AllTeachers.value = res.data.Artists; 
      });
    } catch (err) {
      console.log(err);
    }
    const GoToArtistsContent = (publish) => {
        router.push({ name: "TeachersContent", params: { teachers_id: publish.id } });
    };
    return {  AllTeachers ,GoToArtistsContent};
    
  },
}
</script>

<style>

</style>